import React from "react";
import { Select } from "@chakra-ui/react";
import { useIntl, changeLocale } from "gatsby-plugin-intl";

const LanguageSelector = () => {
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    changeLocale(event.target.value);
  };
  return (
    <Select value={intl.locale} onChange={handleChange}>
      <option value="es">{intl.formatMessage({ id: "lang.es" })}</option>
      <option value="en">{intl.formatMessage({ id: "lang.en" })}</option>
    </Select>
  );
};

export default LanguageSelector;
