import React from "react";
import { useColorMode } from "@chakra-ui/react";
import Toggle from "react-toggle";

const Dark = ({ icon, className }) => <span className={className}>{icon}</span>;
const Light = ({ icon, className }) => (
  <span className={className}>{icon}</span>
);

declare global {
  interface Window {
    __setPreferredTheme: (theme: string) => void;
  }
}

const ColorThemeSwitch = () => {
  const { toggleColorMode, colorMode } = useColorMode();
  React.useEffect(() => {
    localStorage.setItem("theme", colorMode);
    window.__setPreferredTheme(colorMode);
  }, [colorMode]);
  const toggleClass = "flex items-center h-2 justify-center w-2";
  return (
    <Toggle
      checked={colorMode === "dark"}
      onChange={toggleColorMode}
      icons={{
        checked: <Dark icon="🌜" className={toggleClass} />,
        unchecked: <Light icon="🌞" className={toggleClass} />,
      }}
    />
  );
};

export default ColorThemeSwitch;
