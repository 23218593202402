import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import HeaderLink from "./header-link";
import LogoName from "../logo";
import LanguageSelector from "../language";
import ColorToggle from "../color-theme";

const Header = () => {
  const intl = useIntl();
  return (
    <nav className="bg-gray-100 dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <LogoName />
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <HeaderLink
                  to="/blog"
                  title={intl.formatMessage({ id: "nav.blog" })}
                />
                <HeaderLink
                  to="/guides"
                  title={intl.formatMessage({ id: "nav.guides" })}
                />
                <HeaderLink
                  to="/courses"
                  title={intl.formatMessage({ id: "nav.courses" })}
                />
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex space-x-4 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <LanguageSelector />
            <ColorToggle />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
