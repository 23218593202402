import React from "react";
import { Link } from "gatsby-plugin-intl";
import { useColorModeValue } from "@chakra-ui/react";

type HeaderProps = {
  to: string;
  title: string;
};

const HeaderLink: React.FC<HeaderProps> = ({ to, title }) => {
  const isActive = false;
  const inactiveClasses = `text-gray-900 dark:text-white hover:bg-gray-700 dark:hover:bg-gray-400 hover:text-white dark:hover:text-gray-900`;
  const activeClasses =
    "bg-gray-900 dark:bg-gray-100 text-white dark:text-gray-900";
  const baseClasses = isActive ? activeClasses : inactiveClasses;
  return (
    <Link
      to={to}
      className={`${baseClasses} px-3 py-2 rounded-md text-sm font-medium`}
    >
      {title}
    </Link>
  );
};

export default HeaderLink;
